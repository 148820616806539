import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Projects from './Pages/Projects';
import ProjectsCtegoriList from './Pages/ProjectsCtegoriList';
import ProjectView from './Pages/ProjectView';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "projects",
    element: <Projects/>,
  },
  {
    path: "project_category_list",
    element: <ProjectsCtegoriList/>,
  },
  {
    path: "Project_View",
    element: <ProjectView/>,
  }
  
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

<RouterProvider router={router}/>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
